import { LockClosedIcon } from "@heroicons/react/solid";

export default function Signup() {
  return (
    <>
      <div className="bg-black h-screen">
        <div className="bg-black min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
          <div className="max-w-md w-full space-y-8">
            <div>
              <img
                className="mx-auto w-auto"
                src="bloxzzers.png"
                alt="Workflow"
              />
            </div>
            <form className="mt-8 space-y-6" action="#" method="POST">
              <input type="hidden" name="remember" defaultValue="true" />
              <div className="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-yellow-600 focus-within:border-yellow-600">
                <label
                  htmlFor="name"
                  className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-black text-xs font-medium text-white"
                >
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  placeholder="johndoe@gmail.com"
                  className="block w-full border-0 p-0 text-white bg-black placeholder-gray-500 focus:ring-0 sm:text-sm"
                />
              </div>
              <div className="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-yellow-600 focus-within:border-yellow-600">
                <label
                  htmlFor="name"
                  className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-black text-xs font-medium text-white"
                >
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  placeholder="••••••••"
                  className="block w-full border-0 p-0 text-white bg-black placeholder-gray-500 focus:ring-0 sm:text-sm"
                />
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    required
                    className="h-4 w-4 text-yellow-500 focus:ring-yellow-500 border-gray-300 rounded"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 block text-xs text-white"
                  >
                    By signing up, you agree to our                 <a href="#" className="font-medium hover:underline underline-offset-4 text-yellow-500 hover:text-yellow-600">
                  Terms of Service
                </a> and                 <a href="#" className="font-medium text-yellow-500 hover:underline underline-offset-4 hover:text-yellow-600">
                  Privacy Policy.
                </a>
                  </label>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
                >
                  <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                    <LockClosedIcon
                      className="h-5 w-5 text-white group-hover:text-gray-200"
                      aria-hidden="true"
                    />
                  </span>
                  Sign up
                </button>
              </div>
            </form>
            <p className="mt-2 text-center text-sm text-white">
                Or{' '}
                <a href="/login" className="font-medium text-yellow-500 hover:text-yellow-600">
                  login to your account
                </a>
              </p>
          </div>
        </div>
      </div>
    </>
  );
}
