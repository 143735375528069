

export default function Error404() {
  return (
    <>
        <div className="bg-black h-screen">
        <div className="min-h-full pt-16 pb-12 flex flex-col bg-black">
        <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex-shrink-0 flex justify-center">
            <h2 className="inline-flex text-white ">
            onii chan..
            </h2>
          </div>
        </main>
      </div>
        </div>
    </>
  )
}
