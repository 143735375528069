

export default function Error404() {
  return (
    <>
        <div className="bg-black h-screen">
        <div className="min-h-full pt-16 pb-12 flex flex-col bg-black">
        <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex-shrink-0 flex justify-center">
            <a href="/" className="inline-flex">
              <span className="sr-only">Workflow</span>
              <img
              className="mx-auto h-20 w-auto"
              src="https://www.bloxzzers.com/static/media/bloxzzers.643cb51cade20f979416.png"
              alt="Workflow"
            />
            </a>
          </div>
          <div className="py-16">
            <div className="text-center">
              <p className="text-sm font-semibold text-yellow-400 uppercase tracking-wide">404 error</p>
              <h1 className="mt-2 text-4xl font-bold text-white tracking-tight sm:text-5xl">Page not found.</h1>
              <p className="mt-2 text-base text-gray-500">Sorry, we couldn’t find the page you’re looking for.</p>
              <div className="mt-6">
                <a href="/" className="text-base font-medium text-white hover:text-gray-300">
                  Go back home<span aria-hidden="true"> &rarr;</span>
                </a>
              </div>
            </div>
          </div>
        </main>
        <footer className="flex-shrink-0 max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <nav className="flex justify-center space-x-4">
            <span className="inline-block border-gray-300" aria-hidden="true" />
            <a href="#" className="text-sm font-medium text-gray-500 hover:text-gray-600">
              Status
            </a>
            <span className="inline-block border-l border-gray-300" aria-hidden="true" />
            <a href="https://twitter.com/bloxzzers" className="text-sm font-medium text-gray-500 hover:text-gray-600">
              Twitter
            </a>
          </nav>
        </footer>
      </div>
        </div>
    </>
  )
}
